import axios from 'axios';
import Links from 'src/constants/Links';

const baseUrl = Links.BASE_URL_WITHOUT_TENANT;
let accessTokenId = -1;
const Api = {
	putAccessTokenInfo(accessToken: any) {
		accessTokenId !== -1 && axios.interceptors.request.eject(accessTokenId);
		accessTokenId = axios.interceptors.request.use(
			(config: any) => {
				config.headers['Authorization'] = !accessToken ? undefined : `JWT ${accessToken}`;
				return config;
			},
			function (error) {
				return Promise.reject(error);
			},
		);
	},


	getPromise(endpoint: string) {
		return new Promise((resolve, reject) => {
			axios
				.get(baseUrl + endpoint)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error));
		});
	},

	getPromiseWithParams(endpoint: string, params: any) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'GET',
				params: params,
				url: baseUrl + endpoint,
			})
				.then((response) => resolve(response.data))
				.catch((error) => reject(error));
		});
	},

	postPromise(endpoint: string, data: any) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				data: data,
				url: baseUrl + endpoint,
			})
				.then((response) => resolve(response.data))
				.catch((error) => reject(error));
		});
	},

	postPromiseWithoutData(endpoint: string) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				url: baseUrl + endpoint,
			})
				.then((response) => resolve(response.data))
				.catch((error) => reject(error));
		});
	},


	deletePromise(endpoint: string, data: any) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'DELETE',
				data: data,
				url: baseUrl + endpoint,
			})
				.then((response) => resolve(response.data))
				.catch((error) => reject(error));
		});
	},

	putPromise(endpoint: string, data: any) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'PUT',
				data: data,
				url: baseUrl + endpoint,
			})
				.then((response) => resolve(response.data))
				.catch((error) => reject(error));
		});
	},
};
export default Api;
