import React, {lazy} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import Login from "../views/login/Login";
import {useDispatch, useSelector} from "../redux/store";
import PageWrapper from "../layouts/full/logbook/PageWrapper";
import Logout from "../views/logout/Logout";
import Dashboard from "../views/logbook/dashboard";
import UrunlerTable from "../views/logbook/urunler/urunlerTable";
import {logout} from "../redux/actions/authActions";

/* ****Pages***** */
const Routes = () => {
	const LandingPage = Loadable(lazy(() => import('../views/landingPage/LandingPage')));
	const accessToken: any = useSelector((state) => state.auth.accessToken)
	const exp = useSelector(state => state.auth.exp);
	const dispatch = useDispatch();
	if (exp != null && exp <= new Date().valueOf() / 1000) {
		dispatch(logout());
	}

	const publicRoutes: any[] = [{
		path: '/Menu',
		element: <Outlet/>,
		children: [
			{
				path: '/Menu',
				element: <LandingPage/>,
			},
			{
				path: '/Menu/:kategori',
				element: <LandingPage/>,
			}
		],
	},
		{
			path: '*',
			element: <Navigate to="/Menu"/>,
		},]
	return !accessToken ? [...publicRoutes,
		{
			path: '/Logbook',
			element: <Outlet/>,
			children: [
				{
					path: '/Logbook',
					element: <Login/>,
				},
				{
					path: '*',
					element: <Navigate to="/Logbook"/>,
				}
			]
		}
	] : [...publicRoutes,
		{
			path: '/Logbook',
			element: <PageWrapper/>,
			children: [
				{
					path: '/Logbook',
					element: <Dashboard/>,
				},
				{
					path: '/Logbook/products',
					element: <UrunlerTable/>,
				},
				{
					path: '/Logbook/logout',
					element: <Logout/>,
				},
				{
					path: '*',
					element: <Navigate to="/Logbook"/>,
				}
			],
		}
	];
}

export default Routes;
