import AppBar from "./AppBar";
import {Outlet} from "react-router-dom";
import {Paper} from "@mui/material";

const PageWrapper = (Component: any) => {
	return <>
		<AppBar/>
		<Paper sx={{
			display: "flex",
			width: "calc(100% - 2rem)",
			height: 'calc(100% - 7rem)',
			margin: "1rem",
			padding: "1rem",
			backgroundColor: "white",
			alignItems: "start",
			overflowY: "scroll",
		}}>
			<Outlet/>
		</Paper>
	</>
}

export default PageWrapper