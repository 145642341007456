import {CssBaseline, ThemeProvider} from '@mui/material';
import {useRoutes} from 'react-router-dom';
import {ThemeSettings} from './theme/Theme';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import Routes from './routes/Routes';
import {useSelector} from "./redux/store";
import Api from "./api";

function App() {
	const routing = useRoutes(Routes());
	const accessToken = useSelector(state => state.auth.accessToken);
	Api.putAccessTokenInfo(accessToken)
	const theme = ThemeSettings();

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline/>
			<ScrollToTop>{routing}</ScrollToTop>
		</ThemeProvider>
	);
}

export default App;
