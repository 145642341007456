import Api from '../index';

const LogbookApi = {
	login(credentials: any) {
		return Api.postPromise('/logbook/token/', credentials).then((resp: any) => {
			return resp?.token;
		});
	},

	getMenuKategorileri() {
		return Api.getPromise('/menu/kategoriler');
	},

	getKategoriSayfalari(id: number) {
		return Api.getPromise('/menu/kategorisayfalar/' + id);
	},

	getUrunler(id: number) {
		return Api.getPromise('/menu/sayfaurunler/' + id);
	},

	getKampanyalar() {
		return Api.getPromise('/menu/kampanyalar');
	},

	getEtkinlikler() {
		return Api.getPromise('/menu/etkinlikler');
	},
};

export default LogbookApi;
