import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LogbookApi from 'src/api/staffApi/logbookApi'
import {useDispatch} from "../../redux/store";
import {login} from "../../redux/actions/authActions";
import {useNavigate} from "react-router";


export default function Login() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const credentials = {
			username: data.get('username'),
			password: data.get('password'),
		};
		LogbookApi.login(credentials).then((resp: any) => {
			dispatch(login(resp))
			navigate("/logbook")
		})
	};

	return (
		<Box
			component="main"
			sx={{
				display: 'flex',
				flex: '1 1 auto'
			}}
		>
			<Grid
				container
				sx={{flex: '1 1 auto'}}
			>
				<Grid
					xs={12}
					lg={3}
					sx={{
						backgroundColor: 'background.paper',
						display: 'flex',
						flexDirection: 'column',
						position: 'relative'
					}}
				>
					<Box
						component="header"
						sx={{
							left: 0,
							p: 3,
							position: 'fixed',
							top: 0,
							width: '100%'
						}}
					>
					</Box>

					<Grid sx={{minHeight: '100vh', justifyContent: 'center'}}>
						<Container component="main" maxWidth="xs">
							<CssBaseline/>
							<Box
								sx={{
									marginTop: 8,
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}
							>
								<Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
									<LockOutlinedIcon/>
								</Avatar>
								<Typography component="h1" variant="h5">
									Sign in
								</Typography>
								<Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
									<TextField
										margin="normal"
										required
										fullWidth
										id="username"
										label="Username"
										name="username"
										autoComplete="username"
										autoFocus
									/>
									<TextField
										margin="normal"
										required
										fullWidth
										name="password"
										label="Password"
										type="password"
										id="password"
										autoComplete="current-password"
									/>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										sx={{mt: 3, mb: 2, color: "white"}}
									>
										Sign In
									</Button>
								</Box>
							</Box>
						</Container>
					</Grid>
				</Grid>
				<Grid
					xs={12}
					lg={9}
					sx={{
						alignItems: 'center',
						background: 'radial-gradient(50% 50% at 50% 50%, #122647 0%, #090E23 100%)',
						color: 'white',
						display: 'flex',
						justifyContent: 'center',
						'& img': {
							maxWidth: '100%'
						}
					}}
				>
					<Box sx={{p: 0}}>
						<img
							style={{width: '100%', height: "100vh"}}
							alt=""
							src="/assets/bg.jpg"
						/>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);

}