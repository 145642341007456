import * as React from 'react';
import {useDispatch} from "../../redux/store";
import {logout} from "../../redux/actions/authActions";
import {Typography} from "@mui/material";


const Logout = () => {
	const dispatch = useDispatch();
	dispatch(logout());


	return (
		<>
			<Typography>
				logout
			</Typography>
		</>
	);

}

export default Logout;