import * as React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {useNavigate} from "react-router";


const pages = [{
	title: 'Ürünler',
	link: '/Logbook/products'
}, {
	title: 'Hesap',
	link: '/Logbook/accounting'
}, {
	title: 'Eksikler',
	link: '/Logbook/shortage'
}];
const settings = [{
	title: 'Profil',
	link: '/Logbook/profile'
}, {
	title: 'Hesap',
	link: '/Logbook/account'
}, {
	title: 'Çıkış Yap',
	link: '/Logbook/logout'
}];

function AppBar() {
	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
	const navigate = useNavigate();
	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	return (
		<MuiAppBar position="static">
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<AdbIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}/>
					<Typography
						variant="h6"
						noWrap
						component="a"
						href="/Logbook"
						sx={{
							mr: 2,
							display: {xs: 'none', md: 'flex'},
							fontFamily: 'monospace',
							fontWeight: 700,
							letterSpacing: '.3rem',
							color: 'inherit',
							textDecoration: 'none',
						}}
					>
						LOGBOOK
					</Typography>

					<Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="inherit"
						>
							<MenuIcon/>
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{display: {xs: 'block', md: 'none'}}}
						>
							{pages.map((page) => (
								<MenuItem
									key={page.title}
									onClick={() => {
										navigate(page.link);
										handleCloseNavMenu();
									}}
									sx={{margin: ".3rem"}}
								>
									<Typography sx={{textAlign: 'center'}}>{page.title}</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>
					<AdbIcon sx={{display: {xs: 'flex', md: 'none'}, mr: 1}}/>
					<Typography
						variant="h5"
						noWrap
						component="a"
						href="/Logbook"
						sx={{
							mr: 2,
							display: {xs: 'flex', md: 'none'},
							flexGrow: 1,
							fontFamily: 'monospace',
							fontWeight: 700,
							letterSpacing: '.3rem',
							color: 'inherit',
							textDecoration: 'none',
						}}
					>
						LOGBOOK
					</Typography>
					<Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
						{pages.map((page) => (
							<Button
								key={page.title}
								onClick={() => {
									navigate(page.link);
									handleCloseNavMenu();
								}}
								sx={{margin: ".3rem", color: 'white', display: 'block'}}
							>
								{page.title}
							</Button>
						))}
					</Box>
					<Box sx={{flexGrow: 0}}>
						<Tooltip title="Open settings">
							<IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
								<Avatar alt="U" src="/static/images/avatar/2.jpg"/>
							</IconButton>
						</Tooltip>
						<Menu
							sx={{mt: '45px'}}
							id="menu-appbar"
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={Boolean(anchorElUser)}
							onClose={handleCloseUserMenu}
						>
							{settings.map((setting) => (
								<MenuItem key={setting.title} onClick={() => {
									navigate(setting.link);
									handleCloseUserMenu();
								}}>
									<Typography sx={{textAlign: 'center'}}>{setting.title}</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>
				</Toolbar>
			</Container>
		</MuiAppBar>
	);
}

export default AppBar;